import { createRef } from "react";
import { map } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import { t } from "@/i18n-js/instance";
import CircleLogo from "@circle-assets/images/logo.svg";
import { usePunditUserContext } from "@circle-react/contexts";
import { getCommunityLogoUrl } from "@circle-react/helpers/communityHelpers";
import { CSRFTokenField } from "@circle-react-uikit/CSRFTokenField";
import { Loader } from "@circle-react-uikit/Loader";

export interface ErrorTemplateProps {
  title: string;
  errorCode: number;
  [key: string]: any;
}

export const ErrorTemplateComponent = ({
  title,
  errorCode,
  ...passThroughProps
}: ErrorTemplateProps) => {
  const { currentCommunity, isLoading } = usePunditUserContext();
  const recaptchaRef = createRef<ReCAPTCHA>();

  if (isLoading) {
    return <Loader center />;
  }

  let communityUrl = "https://circle.so";
  let logoUrl = CircleLogo;
  let brandColor: any;

  if (currentCommunity) {
    communityUrl = window.location.origin;
    logoUrl = getCommunityLogoUrl(currentCommunity);
    brandColor = currentCommunity.prefs.brand_color;
  }

  const textareaPlaceholder = currentCommunity?.has_plus_plan
    ? "error_page.message_placeholder_circle_plus"
    : "error_page.message_placeholder";

  const handleSubmit = async (_data: any, _: any, event: any) =>
    recaptchaRef?.current?.executeAsync().then(() => {
      event?.target?.submit?.();
    });

  return (
    <div className="center-layout error-layout notification-error">
      <div className="center-container">
        <div className="community__brand">
          {logoUrl ? (
            <a href={communityUrl} target="_blank" rel="noopener noreferrer">
              <img loading="lazy" alt="" className="logo" src={logoUrl} />
            </a>
          ) : (
            <a
              className="brand-icon brand-icon__initial"
              style={{ backgroundColor: brandColor }}
              target="_blank"
              rel="noopener noreferrer"
              href={communityUrl}
            >
              {currentCommunity?.name?.[0]}
            </a>
          )}
        </div>
        <div className="mb-12">
          <h1>{title}</h1>
        </div>
        <h4 className="mb-6"> {t("error_page.suspect_bug")}</h4>
        <form
          action="/error_reports/send_email_notification"
          acceptCharset="UTF-8"
          method="post"
          // @ts-expect-error-next-line -- TODO: Unsure if intentional
          onSubmit={handleSubmit}
        >
          <CSRFTokenField />
          <div className="form-group mb-4">
            <label className="required sr-only" htmlFor="error_detail">
              {t("error_page.error_detail")}
            </label>
            <textarea
              placeholder={t(textareaPlaceholder)}
              rows={7}
              cols={50}
              required
              name="error_detail"
              id="error_detail"
              className="form-control placeholder:text-light placeholder:opacity-100"
            />
          </div>
          {window.CAPTCHA_SITE_KEY && (
            <div className="mb-4 flex items-center justify-center">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={window.CAPTCHA_SITE_KEY}
                size="invisible"
              />
            </div>
          )}
          <input
            value={errorCode}
            type="hidden"
            name="error_status"
            id="error_status"
          />
          <input
            value={window.location.href}
            type="hidden"
            name="error_url"
            id="error_url"
          />
          <input
            type="submit"
            name="commit"
            value={t("error_page.submit_report")}
            className="btn btn-primary"
            data-disable-with="Submit report"
          />
          {map(passThroughProps, (value, key) => (
            <input key={key} name={key} type="hidden" value={value} />
          ))}
        </form>
      </div>
    </div>
  );
};
