import type { ErrorInfo, ReactNode } from "react";
import { Component } from "react";
import { delay } from "lodash";
import { PageNotFound } from "@/react/components/shared/ErrorPages/PageNotFound";
import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { NotFoundError } from "@circle-react/config/CustomErrors";

export type RenderFunc = (props: ErrorProps) => ReactNode;

export interface ErrorBoundaryProps {
  renderFunc?: RenderFunc;
  handleNotFoundError?: boolean;
  children?: ReactNode;
  shouldAutoReload?: boolean;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
  reloadCount: number;
  error?: Error;
  componentStack?: string | null;
  errorStack?: string;
}

export interface ErrorProps extends Omit<ErrorBoundaryState, "error"> {
  error: string;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: "", reloadCount: 0 };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (window.DISABLE_JS_ERROR_BOUNDARIES == "true") {
      throw error;
    }

    const { reloadCount } = this.state;

    this.setState({
      hasError: true,
      error,
      componentStack: errorInfo?.componentStack,
      errorMessage: error?.message,
      errorStack: error.stack,
      reloadCount: reloadCount,
    });

    console.error("ErrorBoundary Error", error, errorInfo);

    const isNotFoundError = error instanceof NotFoundError;
    if (!isNotFoundError) {
      notifyBugsnag(error);
    }

    const { shouldAutoReload } = this.props;
    shouldAutoReload && reloadCount < 3 && delay(this.handleReload, 3000);
  }

  handleReload = () => {
    this.setState(prevState => ({
      hasError: false,
      errorMessage: "",
      reloadCount: prevState.reloadCount + 1,
    }));
  };

  override render(): ReactNode {
    const { error, hasError } = this.state;

    const {
      renderFunc,
      handleNotFoundError: shouldHandleNotFoundError,
      children,
    } = this.props;

    const commonProps: ErrorProps = {
      ...this.state,
      error: JSON.stringify(error),
    };

    if (hasError) {
      if (shouldHandleNotFoundError && error instanceof NotFoundError) {
        return <PageNotFound {...commonProps} />;
      }

      if (renderFunc) {
        return renderFunc(commonProps);
      }

      return null;
    }

    return children;
  }
}
