import { QueryClientProvider } from "react-query";
import { queryClient } from "@circle-react/providers/QueryClient/reactQueryConfig";
import type { ErrorTemplateProps } from "./ErrorTemplateComponent";
import { ErrorTemplateComponent } from "./ErrorTemplateComponent";

export const ErrorTemplate = (props: ErrorTemplateProps) => (
  <QueryClientProvider client={queryClient}>
    <ErrorTemplateComponent {...props} />
  </QueryClientProvider>
);
