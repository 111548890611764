import { t } from "@/i18n-js/instance";
import { ErrorTemplate } from "./ErrorTemplate";
import type { ErrorTemplateProps } from "./ErrorTemplateComponent";

export interface PageNotFoundProps
  extends Omit<ErrorTemplateProps, "title" | "errorCode"> {
  errorMessage?: string;
}

/**
 * 404 Error Page
 */
export const PageNotFound = ({
  errorMessage,
  ...passThroughProps
}: PageNotFoundProps) => (
  <ErrorTemplate
    title={errorMessage || t("error_page.page_not_found")}
    errorCode={404}
    {...passThroughProps}
  />
);
